/* ********************************* */
/* Gallery Section */
/* ********************************* */
.gallery-section {
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.gallery-section > img {
  width: 100%;
  max-width: 100%;
  height: auto;
  visibility: hidden;
  opacity: 0;
}
.gallery-section-content {
  position: absolute;
  top: 15%;
  left: 0;
  width: 100%;
  height: 85%;
  display: flex;
  align-items: flex-start;
}
.gallery-content-box {
  box-shadow: 0px 0px 25px #0000005c;
}
.gallery-content-box-heading {
  background-color: #000;
  padding: 143px 85px 66px;
}
.gallery-content-box-heading h3 {
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 3.214rem;
}
.gallery-content-box-description {
  padding: 90px 85px;
  background-color: #fff;
}
.gallery-content-box-description p {
  color: #313131;
  font-family: 'Lato', sans-serif;
  font-size: 1.143rem;
  line-height: 29px;
  font-weight: 500;
}
