/* ********************************* */
/* Hero Section */
/* ********************************* */
.hero-section {
  display: flex;
  height: 100%;
  min-height: 540px;
  margin-top: -72px;
  & img {
    visibility: hidden;
    opacity: 0;
    width: 100%;
    // height: auto;
    height: 685px;
  }
  & .hero-section-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0000004d;
    display: flex;
    align-items: center;
    & h1 {
      font-family: 'Montserrat', sans-serif;
      color: #fff;
      font-weight: 500;
      font-size: 2.286rem;
    }
  }
  .owl-item {
    .hero-section-overlay {
      opacity: 0.5;
      transition: opacity 0.5s ease-in-out;
    }
  }
  .owl-item.active {
    .hero-section-overlay {
      opacity: 1;
    }
  }
  & .bg-image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 540px;
  }
  .hero-caption {
    position: absolute;
    left: 0;
    bottom: 0;
    padding-bottom: 100px;
    p {
      display: inline-block;
      width: 100%;
      color: #ffffff;
      font-family: 'Lato', sans-serif;
      font-size: 0.857rem;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 2px;
      margin-bottom: 0;
    }
    span {
      display: inline-block;
      width: 100%;
      color: #ffffffcc;
      font-family: 'Lato', sans-serif;
      font-size: 1rem;
      font-weight: 400;
      line-height: 29px;
    }
    a {
      display: inline-block;
      width: 100%;
      color: #f5dfa3;
      font-family: 'Lato', sans-serif;
      font-size: 1rem;
      font-weight: 600;
      line-height: 29px;
      text-transform: uppercase;
      letter-spacing: 1px;
      i {
        margin-left: 5px;
      }
    }
  }
}
