// Colors

$primary-color: #000;
$button-color-text: #4C5051;
$secondary-color: #ddd;
$text-primary-color: #393939;
$text-secondary-color: #3a81b8;
$text-secondary-color-dark: #3B5266;
$urban-explorer-journal-gray-background-color: #f7f7f7;
$home-images-gray-background-color: #f9f9f9;
$gray-text-color: #7e7e7e;
$border-light-gray: #EBEBED;
$white-text-over-black: #f5f9fc;
$light-blue-background: #EBF2F8;
$light-gray-background: #F9FBFC;
$gray-line-color: #787878;
$background-button-dark-blue: #284458;
$sign-background-modal-gray: #F3F3F3;
$facebook-button-color:#507CC0;
$google-button-background-color:#E9E9E9;
$reddish-grey-background: #F4F4F4;
$bootstrap-grey-input-border: #ced4da;
$gray-label: #8D9091;
$dark-text: #1B2124;
$dark-text-transparency: #1b2124b0;
$default-link-color: #007bff;
$discount-color: #ca8a92;


// Fonts
$base-font: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
  Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
$nunito_sans-font: 'Corben', 'Nunito Sans', sans-serif;
$lato-font: 'Montserrat', 'Lato', sans-serif;
$helvetica-font: 'Corben', 'Helvetica Neue LT Std', sans-serif;
$montserrat: 'Montserrat', sans-serif;
$corben: 'Corben','Montserrat', sans-serif;
