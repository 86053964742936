@import '../variables';
/* ********************************* */
/* Filter Section */
/* ********************************* */
.filter-section {
  z-index: 5;
}
.filter-container {
  background-color: #fff;
  margin-top: -48px;
  padding: 10px;
  box-shadow: 0px 0px 5px #00000045;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr;
  grid-gap: 2rem;
  justify-content: center;
  align-items: center;
  &.not-home {
    grid-template-columns: 1fr 2fr 1fr;
  }
}
.filter-container button {
  font-family: $montserrat;
  padding: 10px 0;
  width: 100%;
  border: none;
  background-color: transparent;
  position: relative;
  text-align: left;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.filter-container button:hover,
.filter-container button:focus,
.filter-container button:active {
  border: none;
  outline: none;
}
.filter-container button i {
  color: #3a81b8;
}

.calendar__icon {
  // margin-right: 3% !important;
  // margin-left: 0 !important;
  padding-right: 7px;
  position: relative;
  top: -0.15em;
}
.date_picker {
  position: relative;
  display: flex;
  justify-content: space-around;
  border-left: 1px solid hsla(0,0%,59.2%,.3607843137254902) !important;
  border-right: 1px solid hsla(0,0%,59.2%,.3607843137254902) !important;
  padding: 10px 2rem !important;
}

.result-count {
  padding: 20px 0;
}
.result-count p {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 24px;
  color: #7e7e7e;
  font-family: $lato-font;
}
.result-count p span {
  color: #3a81b8;
  font-weight: 700;
}
.filter-container > .search-btn,
.search-btn {
  font-family: $montserrat;
  font-weight: bold;
  color: #fff;
  font-size: 1rem;
  background-color: #3a81b8;
  display: block;
  width: 100%;
  height: 100%;
  float: left;
  text-align: center;
  padding: 24px 0;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.search-btn:hover,
.search-btn:focus,
.search-btn:active {
  color: #fff;
}
.checkin-checkout {
  border: 1px solid #9797975c;
  border-top: none;
  border-bottom: none;
}

.after-filter-tags {
  margin: 2.4rem 0;
  cursor: default;
  h4 {
    margin: 0;
    font-family: $montserrat;
    font-weight: 500;
    color: #304659;
    flex: 1;
    padding: 1.2rem 0;
    @media (min-width: 768px) {
      font-size: 1.429rem;
      line-height: 24px;
      &:not(:last-child){
        border-right: 0.5px solid rgba($color: #979797, $alpha: 0.34);
      }
    }
  }
  .checkin-checkout-calendar {
    display: none;
    position: absolute;
    width: 100%;
    z-index: 10;
  }
}
.checkin-checkout-calendar.active {
  display: block;
}

.filter-highlighted-text {
  color: #000;
}

.results-page-filter {
  background-color: #fff;
}
.results-page-filter .filter-container {
  margin-top: 0;
}
.results-page-filter .filter-container {
  box-shadow: none;
}
.results-page-filter.filter-section {
  z-index: 7;
}


@media (max-width: 1300px){
  .filter-container{
    grid-template-columns: 1fr 17rem 1fr 12rem;
    grid-gap: 1rem;
    &.not-home {
      grid-template-columns: 1fr 2fr 1fr;
    }
  }
  .date_picker{
    padding: 10px 1.5rem !important;
  }
}
@media (max-width: 1000px){
  .filter-container{
    grid-template-columns: 1fr;
    &.not-home {
      grid-template-columns: 1fr;
    }
    .guest__dropdown__container,
    .location__dropdown__container {
      width: 100%;
      left: initial;
    }
  }
  .date_picker{
    border: none !important;
    padding: 10px 15px !important;
  }
}
@media (max-width: 767px){
  .filter-container{
    grid-gap: 0;
    margin-top: 1rem !important;
    padding: 0 ;
    &.not-home {
      grid-template-columns: 1fr;
    }
    .date_picker{
      padding: 10px 0 !important;
    }
  }
}
