@import '../variables';

/* ********************************* */
/* Results Area Section */
/* ********************************* */
.results-area-section {
  position: relative;
  background: linear-gradient(176deg, $urban-explorer-journal-gray-background-color 12%, white 0);
  //padding: 50px 0 0 0;
}
.results-page-above-filters {
  .result-inline-item {
    align-items: center;
    display: flex;
    white-space: nowrap;
    button {
      font-family: $montserrat;
      font-weight: 500;
      font-size: 1.05rem;
    }
    button,
    i {
      color: $text-secondary-color;
      margin-right: 10px;
    }
  }
  .result-inline-item.active {
    button,
    i {
      color: $text-secondary-color-dark;
      font-weight: 700;
    }
    i.fa-map {
      font-weight: 500;
    }
  }
}
.right-side-buttons {
  justify-content: flex-end;
}

.results-page-select {
  background: #ffffff;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  width: 195px;
  height: 48px;
  padding: 0 10px !important;
  appearance: none;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 24px;
  color: #737373;
  font-family: 'Lato', sans-serif;
  background-image: url('../../assets/arrow-down.png');
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 90% center;
  &:hover,
  &:focus,
  &:active {
    outline: none;
  }
}
@media (min-width: 1000px) {
  .result-inline-item:last-child {
    margin-left: 3%;
    // position: relative;
  }
}
.vertical-separator {
  border-right: 1px solid #9797976b;
  height: 16px;
  align-self: center;
  margin-right: 5%;
  margin-left: 5%;
}
.results-page-above-filters ul li:first-child:after {
  content: '';
  background-color: #9797976b;
  width: 1px;
  height: 50%;
  position: absolute;
  top: 6px;
  right: -29px;
}
.results-page-above-filters ul li:nth-child(2) {
  margin-right: 0px;
}
.results-map-container {
  padding: 0;
  margin-bottom: 6%;
  height: 60vh;
}
.new-results-map-container {
  padding: 0;
  height: 85vh;
  .all-booked {
    margin-left: auto;
    margin-right: auto;
    font-size: 2rem;
    text-align: center;
    font-weight: 500;
    color: $text-secondary-color;
    font-family: $corben;
  }
}
.result-page-box {
  background-color: white;
  min-width: 200px;
  height: 100%;
  width: 100%;
  box-shadow: 0 0 12px 5px rgba(9, 9, 9, 0.15);
  .slick-track {
    display: flex;
    align-items: center;
  }
}
.result-page-box .hotel-tag {
  left: 15px;
}

.rooms-meta {
  display: inline-block;
  width: 100%;
  padding: 10px 15px;
  .list-unstyled {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
.rooms-meta p,
.rooms-meta span {
  margin: 0;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.05rem;
  line-height: 17px;
  color: #545454;
}
.rooms-meta.details p {
  font-size: 1.2rem;
}
.rooms-meta ul li {
  // margin-right: 20px !important;
}
.rooms-meta ul li p i {
  color: #3a81b8;
  margin-right: 5px;
}
.results-page-box-container .col-lg-6 {
  margin-bottom: 35px;
}
.results-page-pagination ul li {
  display: inline;
}
.results-page-above-filters ul {
  margin-top: -20px;
}
.results-page-pagination a {
  width: 30px;
  height: 30px;
  background-color: #fff;
  display: inline-block;
  font-family: $lato-font;
  color: #3a81b8;
  border-radius: 50%;
  font-size: 1.143rem;
  font-weight: 500;
  padding-top: 5px;
  line-height: 19px;
}
.results-page-pagination {
  padding-bottom: 5px;
}
.results-page-above-filters .filters-btn {
  font-family: $montserrat;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #5a5a5a;
  height: 48px;
  i,
  .icon {
    color: $text-secondary-color;
    margin-right: 1em;
    width: 0.875rem;
  }
}
/* ********************************* */
/* Hotels Results Section */
/* ********************************* */
.sidebar-filter-box {
  background-color: #fff;
}
.sidebar-filter-box .items-counter {
  box-shadow: none;
  width: 100%;
  padding: 0 25px 25px;
  border-bottom: 0.5px solid #97979726;
  ul {
    padding: 0;
  }
  li {
    padding: 10px 0;
    list-style: none;
  }
}
.sidebar-filter-box .items-counter ul li {
  padding-left: 0;
  border-bottom: none;
}
.sidebar-filter-title {
  padding: 25px;
}
.sidebar-filter-title h3 {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.143rem;
  line-height: 36px;
  color: #333333;
  margin: 0;
}
.price-range-box {
  padding-bottom: 25px;
  border-bottom: 0.5px solid #97979726;
}
.price-range-box .sidebar-filter-title span {
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 24px;
  text-align: right;
  color: #3a81b8;
  font-family: 'Lato', sans-serif;
  position: relative;
}
.price-range-box .sidebar-filter-title span:before {
  content: '$';
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 24px;
  text-align: right;
  color: #3a81b8;
  font-family: 'Lato', sans-serif;
}
.price-range-box .sidebar-filter-title h3 {
  display: inline-block;
}
.price-range-box .sidebar-filter-title .min-max-value {
  float: right;
  display: inline;
  padding-top: 7px;
}
.filter-price-ranger {
  padding: 0 25px;
  .slider-horizontal {
    width: 100%;
    .slider-track {
      height: 4px;
    }
    .slider-handle {
      top: 2px;
      width: 10px;
      height: 10px;
      background-color: #fff;
      background-image: linear-gradient(to bottom, #fff, #fff);
    }
    .slider-handle:before {
      content: '';
      height: 6px;
      width: 6px;
      background-color: #3a81b8;
      position: absolute;
      border-radius: 50%;
      top: 2px;
      left: 2px;
    }
    .slider-selection {
      background-color: #3a81b8;
      background-image: linear-gradient(to bottom, #3a81b8, #3a81b8);
    }
  }
}
.min-max-value-container {
  display: inline-block;
  width: 100%;
}
.min-max-value-container span {
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 24px;
  font-family: 'Lato', sans-serif;
  color: #ababab;
}
.min-max-value-container span:first-child {
  float: left;
  display: inline;
  width: auto;
  padding-left: 20px;
}
.min-max-value-container span:last-child {
  float: right;
  display: inline;
  width: auto;
  padding-right: 20px;
}
.update-filters {
  display: inline-block;
  width: 100%;
  padding: 25px;
}
.sidebar-filter-widget {
  padding: 25px;
}
.sidebar-filter-widget .items-counter {
  width: 100%;
  box-shadow: none;
  padding: 0;
  ul {
    padding: 0;
    list-style: none;
  }
}
.item-box {
  display: flex;
  justify-content: space-between;
}
.sidebar-filter-widget .custom-switch .custom-control-label::before {
  left: -36px;
  width: 36px;
  pointer-events: all;
  border-radius: 80px;
  height: 24px;
  background-color: #d8d8d8;
  border-color: #d8d8d8;
}
.sidebar-filter-widget .custom-switch .custom-control-label::after {
  width: calc(24px - 4px);
  height: calc(24px - 4px);
  background-color: #fff;
  border-radius: 5rem;
}
.sidebar-filter-widget .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(12px);
  transform: translateX(19px);
}
.sidebar-filter-widget .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #83b9e3;
  background-color: #83b9e3;
}
.sidebar-filter-widget .custom-switch {
  margin-top: -3px;
}
.sidebar-filter-widget .items-counter ul li {
  border: none;
  padding-left: 0;
}
.sidebar-separator {
  border-bottom: 0.5px solid rgba(151, 151, 151, 0.15);
  margin: 25px 0;
}
.sidebar-filter-content {
  padding: 15px 0;
}
.sidebar-filter-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.sidebar-filter-content ul li {
  display: inline-block;
  width: 100%;
  position: relative;
}
.sidebar-filter-content ul li:before {
  content: '';
  width: 100%;
  height: 5px;
  border-bottom: 2px dashed #e5e8f3;
  display: inline-block;
  margin: 0 auto;
  position: absolute;
  top: 15px;
}
.sidebar-filter-content ul li span {
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 36px;
  color: #7e7e7e;
  font-family: 'Lato', sans-serif;
  background-color: #fff;
  z-index: 9999;
  position: relative;
  padding: 0 10px;
}
.sidebar-filter-content ul li span:first-child {
  display: inline;
  float: left;
  padding-left: 0;
}
.sidebar-filter-content ul li span:last-child {
  display: inline;
  float: right;
  padding-right: 0;
}
.amenities-list {
  height: 245px;
  overflow-y: scroll;
}
.amenities-list::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0;
  border-radius: 0;
  background: #000;
}
.amenities-list::-webkit-scrollbar-thumb:window-inactive {
  background: #000;
}
.amenities-list li:before {
  display: none !important;
}
.amenities-list li {
  height: 40px;
  padding-top: 8px;
  padding-left: 7px;
  transition: all 0.5s ease;
}
.amenities-list li .form-check-input {
  position: absolute;
  margin-top: 5px;
  margin-left: -1.5rem;
  width: 15px;
  height: 15px;
  border-radius: 0px !important;
}
.amenities-list li label {
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 100%;
  color: #7e7e7e;
  font-family: 'Lato', sans-serif;
  padding-left: 5px;
}
.amenities-list li:hover {
  background-color: rgba(58, 129, 184, 0.1);
}
.results__image {
  max-height: 50vh;
  width: 100%;
  object-fit: contain;
  display: flex;
  justify-content: center;
  img {
    max-height: 50vh;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}
.results__image__map__without__text {
  height: 40vh;
  object-fit: contain;
  display: flex;
  justify-content: center;
  img {
    height: 40vh;
    margin-left: auto;
    margin-right: auto;
  }
}
.results__image__map {
  height: 30vh;
  object-fit: contain;
  display: flex;
  justify-content: center;
  img {
    height: 30vh;
    margin-left: auto;
    margin-right: auto;
  }
}
/*@media (max-width: 1764px) {
  .results__image {
    max-height: 395px;
    img {
      max-height: 395px;
    }
  }
}
@media (max-width: 1199px) {
  .results__image {
    max-height: 302px;
    img {
      max-height: 302px;
    }
  }
}
@media (max-width: 767px) {
  .results__image {
    max-height: 371px;
    img {
      max-height: 371px;
    }
  }
}

@media (max-width: 575px) {
  .results__image {
    max-height: 253px;
    img {
      max-height: 253px;
    }
  }
}*/
