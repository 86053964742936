@import '../variables';
/* ********************************* */
/* Carousel Section */
/* ********************************* */
.carousel-section {
  padding: 100px 0;
  background-color: #fff;
}
.slider-heading select {
  font-family: 'Lato', sans-serif;
  font-size: 1.429rem;
  color: #1f1f1f;
  border: none;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
}
.slider-category {
  padding: 70px 0 50px 0;
}
.category-link-item {
  font-family: 'Lato', sans-serif;
  color: #252525;
  font-size: 1.286rem;
  letter-spacing: 0.69px;
  text-transform: uppercase;
  font-weight: 600;
  height: 60px;
  display: inline-block;
  padding-top: 20px;
}
.slider-category .owl-item.active.center .category-link-item {
  border-bottom: 2px solid #3a81b8;
}
.category-link-item:hover,
.category-link-item:focus,
.category-link-item:active {
  color: #3a81b8;
}
.slick-prev {
  left: 25px;
  z-index: 6;
}
.slick-prev:before {
/*  font-size: 50px;
  color: $reddish-grey-background;*/
  content: '';
  width: 50px !important;
  height: 50px !important;
  background-image: url('../../assets/left.png');
  background-size: 40px;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  opacity: 1;
  position: relative;
  top: -100%;
}
.slick-next {
  right: 55px;
  z-index: 9;
}

@media (min-width: 700px) and (max-width: 999px)  {
  .slick-next {
    right: 100px;
  }
  .slick-prev {
    left: 70px;
  }
}
.slick-next:before {
/*  color: $reddish-grey-background;
  font-size: 50px;*/
  content: '';
  height: 50px !important;
  width: 50px !important;
  background-image: url('../../assets/right.png');
  background-size: 40px;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
  opacity: 1;
  position: relative;
  top: -100%;
}
.hotel-tag-inner {
  background-color: #fff;
  width: 110px;
  text-align: center;
  display: inline-block;
  padding: 5px 0;
  box-shadow: 8px 6px 20px 0 grey;
}
.hotel-tag-inner img {
  height: 25px;
  width: auto !important;
  image-rendering: -webkit-optimize-contrast;
  margin: 0 auto;
}
.hotel-tag {
  position: absolute;
  top: 25px;
  left: 0;
  z-index: 6;
}
.wishlist-icon {
  position: absolute;
  right: 0;
  top: -35px;
  right: 15px;
}
.wishlist-icon span {
  position: relative;
  display: none;
  right: 35px;
  top: 20px;
}
.wishlist-icon span i {
  position: absolute;
}
.wishlist-icon .unlike.active,
.wishlist-icon .liked.active {
  display: block;
}
.wishlist-icon span .border-icon {
  color: #fff;
  font-size: 1.857rem;
  top: -1px;
}
.wishlist-icon span .fill-icon {
  color: #c9c9c9;
  font-size: 1.643rem;
  left: 1.5px;
  top: 0.7px;
}
.wishlist-icon .liked i {
  color: red;
}
.hotel-meta-inner {
  display: block;
  float: left;
  width: 65%;
  padding: 0 35px;
  text-transform: capitalize;
}
.hotel-meta-inner span {
  color: #3a81b8;
  font-family: 'Lato', sans-serif;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
}
.hotel-meta-inner h3 {
  font-family: 'Montserrat', sans-serif;
  color: #2d2d2d;
  font-size: 1.571rem;
  // text-transform: uppercase;
  font-weight: 700;
}
@media (max-width: 992px) {
  .hotel-meta-inner h3 {
    font-size: 1.3rem;
  }
}
.hotel-meta-price {
  width: 35%;
  border-left: 1px solid #86868678;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
}
.hotel-meta-price p {
  font-family: 'Montserrat', sans-serif;
  color: #2d2d2d;
  font-style: italic;
  font-weight: 500;
  font-size: 1.286rem;
  margin: 0;
  line-height: 43px;
}
.hotel-meta-price p strong {
  font-style: normal;
  color: #3a81b8;
  font-weight: 700;
}

.carousel-dropdown {
  min-width: 11rem;
  input {
    min-width: 150px;
    font-weight: normal;
    font-size: 1.8rem;
    letter-spacing: 3.13px;
  }
}

@media (max-width: 576px) {
  .hotel-meta {
    padding-top: 0px!important;
    .hotel-meta-inner {
      padding: 0 0 0 15px;
      span {
        font-size: 0.8rem;
      }
      h3 {
        font-size: 1.3rem;
      }
    }
    .hotel-meta-price {
      padding-right: 15px;
    }
  }
}
