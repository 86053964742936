@import "../variables";
/* ********************************* */
/* Footer */
/* ********************************* */
footer {
  background-color: #fff;
  border-top: 1px solid hsla(0, 0%, 59.2%, 0.5490196078431373);
}
.footer-right-border {
  border-right: 1px solid #9797978c;
  padding: 100px 0;
  position: relative;
}
.footer-widget h3 {
  margin: 0;
  font-family: $montserrat;
  font-size: 1.429rem;
  color: #1e1e1e;
  line-height: 24px;
  font-weight: 400;
}
.footer-widget ul {
  margin-top: 40px;
}
.footer-widget ul li {
  margin-bottom: 20px;
}
.footer-widget ul li a {
  font-family: $montserrat;
  color: #7b7b7b;
  font-size: 1.143rem;
  font-weight: 400;
  margin-right: 20px;
}
.footer-widget ul li a.email {
  color: #4a8dbf;
}
.copy-right-footer {
  position: absolute;
  bottom: 100px;
}
.absolute-position {
  position: absolute;
}
.copy-right * {
  font-family: $montserrat;
  color: #7b7b7b;
  font-size: 1rem;
  //text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0;
  font-weight: 400;
}
.footer-right-content {
  padding: 0 0 0 150px;
}
.footer-right-content h3 {
  color: #4a8dbf;
  font-family: $montserrat;
  font-weight: 500;
  font-size: 1.857rem;
}
.footer-right-content p {
  font-family: $montserrat;
  color: #272727;
  font-size: 1.143rem;
  font-weight: 400;
}
.footer-right-content a {
  font-family: $montserrat;
  font-weight: 600;
  font-size: 1rem;
}
.footer-right-content a:not(.__primary-text-color){
  text-transform: uppercase;
  color: #383838;
}
.footer-right-content a i {
  color: #383838;
  font-size: 0.857rem;
  margin-left: 5px;
}
.footer-right-content-col {
  position: relative;
  padding: 100px 0;
}
.footer-nav {
  position: absolute;
  bottom: 100px;
  width: 100%;
}
.footer-nav ul li a {
  font-family: $montserrat;
  color: #7b7b7b;
  font-size: 1rem;
  letter-spacing: 1px;
  margin: 0;
  font-weight: 400;
}
