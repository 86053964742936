@import '../variables';
/* ********************************* */
/* Features Section */
/* ********************************* */
.features-section {
  margin-top: 50px;
  .section-heading {
    h2 {
      font-family: $montserrat;
      font-size: 3.214rem;
      font-weight: 400;
      color: #393939;
    }
    p {
      color: #313131;
      font-family: $lato-font;
      font-size: 1.143rem;
      line-height: 29px;
      font-weight: 400;
    }
  }
}
.section-heading h2 {
  color: #242424;
  font-size: 3.214rem;
  font-family: $montserrat;
  font-weight: 400 !important;
  vertical-align: middle;
  line-height: 50px;
}
.section-heading a {
  color: #3a81b8;
  font-family: $corben;
  font-size: 1rem;
  line-height: 20px;
  font-weight: 700;
  text-transform: uppercase;
  word-spacing: 2.15px;
  letter-spacing: 2.15px;
}

.features-row {
  margin-top: 50px;
  min-height: 220px;
}
.features-row img {
  height: 80px;
  width: auto;
}
.features-row p {
  color: #000;
  font-family: $montserrat;
  font-size: 1.214rem; // check if it can be changed for 16px 1.143rem
  line-height: 21.38px;
  font-weight: 500;
}
