@import '../variables';

.mobile-show-only,
.mobile-device,
.mobile-hotels-gallery {
  display: none;
}
.mobile-nav {
  background-color: #000;
  display: none;
}
.mobile-footer {
  padding: 50px 0;
  text-align: center;
}
.mobile-social-nav ul li {
  margin: 0 10px;
}
.mobile-social-nav ul li a {
  color: #a9acb5;
  font-size: 1.571rem;
}
.mobile-social-nav ul {
  margin-bottom: 20px !important;
}
.mobile-social-nav ul + a {
  margin-bottom: 20px;
  display: inline-block;
}
.mobile-social-nav p {
  margin: 0;
  color: #2c3f58;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}
.gallery-content-box-description {
  padding: 100px;
  background-color: #fff;
}
.gallery-content-box-description-text {
  opacity: 0.8;
}

.blog-section {
  background: $urban-explorer-journal-gray-background-color; /* For browsers that don't support gradients */
  background: -webkit-linear-gradient(176deg, white 12%, $urban-explorer-journal-gray-background-color 0);
  background: -o-linear-gradient(176deg, white 12%, $urban-explorer-journal-gray-background-color 0);
  background: -moz-linear-gradient(176deg, white 12%, $urban-explorer-journal-gray-background-color 0);
  background: linear-gradient(176deg, white 12%, $urban-explorer-journal-gray-background-color 0);
}

.home-images-gradient {
  background: white; /* For browsers that don't support gradients */
  background: -webkit-linear-gradient(170deg, $home-images-gray-background-color 44%, white 0);
  background: -o-linear-gradient(170deg, $home-images-gray-background-color 44%, white 0);
  background: -moz-linear-gradient(170deg, $home-images-gray-background-color 44%, white 0);
  background: linear-gradient(170deg, $home-images-gray-background-color 44%, white 0);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.new-blog-box {
  animation: fadeIn 5s;
  animation-delay: 5s;
  animation-fill-mode: forwards;
}

/*.blog-section::before {
  background: white;
  bottom: 100%;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  right: 0;
  transform-origin: 100% 100%;
  transform: rotate(-15deg);
  width: 150%;
}*/
/* ********************************* */
/* Responsive*/
/* ********************************* */
@media (min-width: 320px) and (max-width: 487px) {
  .right-aligned-on-desktop {
    text-align: center;
  }
  .new-blog-box {
    min-height: auto;
  }
  .carousel-section {
    .row {
      flex-direction: column;
      .dropdown {
        align-self: center;
      }
    }
  }
  .top-header-nav,
  .navigation-section {
    display: none;
  }
  .mobile-nav {
    display: block;
  }
  .alert-dismissible .close {
    height: 100%;
  }
  .menu-icon {
    padding-left: 30px;
    padding-top: 13px;
  }
  .lang_switch {
    margin-top: 15px;
    margin-right: 10px;
  }
  .country_list li a {
    text-align: left;
  }
  .town_list li {
    width: 100%;
  }
  .country_list li a {
    text-align: left;
    padding-left: 40px;
  }
  .town_list li a {
    padding-left: 25px;
  }
  .view_all {
    padding-top: 0 !important;
    padding-left: 25px !important;
  }
  .hero-section {
    margin-top: 0;
  }
  .hero-section-overlay {
    background-color: #0000008f;
  }
  .hero-caption {
    text-align: center;
  }
  .gallery-content-box-heading {
    padding: 50px 40px 50px;
  }
  .gallery-content-box-heading h3 {
    font-size: 25px;
  }
  .filter-container {
    margin-top: 0;
    box-shadow: none;
  }
  .checkin-checkout {
    border: none;
  }
  .date_picker {
    font-size: 0.786rem;
  }
  .date_picker span {
    margin: 0 8px;
  }
  .filter-section {
    background-color: #ffffff;
    padding-bottom: 50px;
  }
  .filter-container .row > div {
    border-bottom: 2px solid #f9f9f9;
  }
  .filter-container .search-btn {
    margin-top: 40px;
    height: auto;
  }
  .gallery-section {
    background-image: none !important;
    background-color: #fff;
    padding-top: 0;
    padding-bottom: 0;
  }
  .gallery-section > img {
    display: none;
  }
  .gallery-section-content {
    position: static;
  }
  .gallery-content-box {
    box-shadow: none;
  }
  .after-filter-tags ul li {
    margin: 0 !important;
    display: block;
    text-align: center;
    float: left;
    width: 100%;
  }
  .after-filter-tags ul li:after {
    display: none;
  }
  .rating-section {
    display: none;
  }
  footer {
    padding: 0 15px;
  }
  .mobile-show-only.active {
    display: block;
  }
  .location-dropdown-container.active:not(.mobile-show-only),
  .guests-dropdown-container.active:not(.mobile-show-only) {
    display: none;
  }
  .items-counter {
    width: 90%;
    margin: 0 auto;
  }
  .item-box .def-number-input button {
    padding: 0 5px;
  }
  .mobile-device {
    display: block;
  }
  .gallery-section-content .container {
    padding: 0;
  }

  .mobile-hotels-gallery {
    display: block;
  }
  .section-heading h2 {
    font-size: 1.786rem;
  }
  /*.blog-section .container {
    padding: 0;
  }*/
  .blog-heading-area {
    text-align: center;
    padding: 30px 0;
  }
  .blog-heading-area div {
    width: 100%;
  }
  .blog-heading-area a {
    display: none;
  }
  .blog-box {
    padding: 0 40px;
    margin-bottom: 0px;
  }
  .desktop-footer {
    display: none !important;
  }
  .filter-highlighted-text {
    color: #3a81b8;
  }
  .guest__dropdown__container,
  .location__dropdown__container {
    width: 100%;
    left: 0;
    top: 4.5em;
  }
  .signup-sidebar-img-container {
    display: none;
  }
  .form-container > button {
    top: 20px;
    position: absolute;
    right: 30px;
  }
}
@media (min-width: 488px) and (max-width: 623px) {
  .right-side-buttons {
    justify-content: space-between !important;
  }
  .result-page-top-nav {
    flex-direction: column-reverse;
  }
  .results-page-above-filters {
    margin-bottom: 20px !important;
  }
  .right-aligned-on-desktop {
    text-align: center;
  }
  .top-header-nav,
  .navigation-section {
    display: none;
  }
  .mobile-nav {
    display: block;
  }
  .alert-dismissible .close {
    height: 100%;
  }
  .menu-icon {
    padding-left: 30px;
    padding-top: 13px;
  }
  .lang_switch {
    margin-top: 15px;
    margin-right: 10px;
  }
  .country_list li a {
    text-align: left;
  }
  .town_list li {
    width: 100%;
  }
  .destination-dropdown {
    top: 122px;
  }
  .country_list li a {
    text-align: left;
    padding-left: 40px;
  }
  .town_list li a {
    padding-left: 25px;
  }
  .view_all {
    padding-top: 0 !important;
    padding-left: 25px !important;
  }
  .hero-section {
    margin-top: 0;
  }
  .hero-section-overlay {
    background-color: #0000008f;
  }
  .hero-caption {
    text-align: center;
  }
  .gallery-content-box-heading {
    padding: 50px 40px 50px;
  }
  .gallery-content-box-heading h3 {
    font-size: 1.786rem;
  }
  .filter-container {
    margin-top: 0;
    box-shadow: none;
  }
  .checkin-checkout {
    border: none;
  }
  .date_picker {
    font-size: 0.786px;
  }
  .date_picker span {
    margin: 0 8px;
  }
  .filter-section {
    background-color: #ffffff;
    padding-bottom: 50px;
  }
  .filter-container .row > div {
    border-bottom: 2px solid #f9f9f9;
  }
  .filter-container .search-btn {
    margin-top: 40px;
    height: auto;
  }
  .gallery-section {
    background-image: none !important;
    background-color: #fff;
    padding-top: 0;
  }
  .gallery-section > img {
    display: none;
  }
  .gallery-section-content {
    position: static;
  }
  .gallery-content-box {
    box-shadow: none;
  }
  .filter-highlighted-text {
    color: #3a81b8;
  }
  .after-filter-tags ul li {
    margin: 0 !important;
    display: block;
    text-align: center;
    float: left;
    width: 100%;
  }
  .after-filter-tags ul li:after {
    display: none;
  }
  .rating-section {
    display: none;
  }
  footer {
    padding: 0 15px;
  }
  .mobile-show-only.active {
    display: block;
  }
  .location-dropdown-container.active:not(.mobile-show-only),
  .guests-dropdown-container.active:not(.mobile-show-only) {
    display: none;
  }
  .items-counter {
    width: 90%;
    margin: 0 auto;
  }
  .item-box .def-number-input button {
    padding: 0 5px;
  }
  .gallery-section-content .container {
    padding: 0;
  }

  .mobile-hotels-gallery {
    display: block;
  }
  .section-heading h2 {
    font-size: 1.786rem;
  }
  /*.blog-section .container {
    padding: 0;
  }*/
  .blog-heading-area {
    text-align: center;
    padding: 30px 0;
  }
  .blog-heading-area div {
    width: 100%;
  }
  .blog-heading-area a {
    display: none;
  }
  .blog-box {
    padding: 0 40px;
    margin-bottom: 0px;
  }
  .desktop-footer {
    display: none;
  }
  .mobile-social-nav ul li {
    margin: 0 10px;
  }
  .mobile-social-nav ul li a {
    color: #a9acb5;
    font-size: 1.571rem;
  }
  .mobile-social-nav ul {
    margin-bottom: 20px !important;
  }
  .mobile-social-nav ul + a {
    margin-bottom: 20px;
    display: inline-block;
  }
  .mobile-social-nav p {
    margin: 0;
    color: #2c3f58;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
  }
  .signup-sidebar-img-container {
    display: none;
  }
  .form-container > button {
    top: 20px;
    position: absolute;
    right: 30px;
  }
}
@media (min-width: 624px) and (max-width: 767px) {
  .right-side-buttons {
    justify-content: space-between !important;
  }
  .result-page-top-nav {
    flex-direction: column-reverse;
  }
  .results-page-above-filters {
    margin-bottom: 20px !important;
  }
  .right-aligned-on-desktop {
    text-align: center;
  }
  .top-header-nav,
  .navigation-section {
    display: none;
  }
  .mobile-nav {
    display: block;
  }
  .alert-dismissible .close {
    height: 100%;
  }
  .top-header-alert-content {
    padding: 0 100px;
  }
  .menu-icon {
    padding-left: 30px;
    padding-top: 13px;
  }
  .lang_switch {
    margin-top: 15px;
    margin-right: 10px;
  }
  .country_list li a {
    text-align: left;
  }
  .town_list li {
    width: 48%;
  }
  .destination-dropdown {
    top: 122px;
  }
  .country_list li a {
    text-align: left;
    padding-left: 40px;
  }
  .town_list li a {
    padding-left: 25px;
  }
  .view_all {
    padding-top: 0 !important;
    padding-left: 25px !important;
  }
  .hero-section {
    margin-top: 0;
  }
  .hero-section-overlay {
    background-color: #0000008f;
  }
  .hero-caption {
    text-align: center;
  }
  .filter-highlighted-text {
    color: #3a81b8;
  }
  .gallery-content-box-heading {
    padding: 50px 40px 50px;
  }
  .gallery-content-box-heading h3 {
    font-size: 1.786rem;
  }
  .filter-container {
    margin-top: 0;
    box-shadow: none;
  }
  .checkin-checkout {
    border: none;
  }
  .date_picker {
    font-size: 0.786rem;
  }
  .date_picker span {
    margin: 0 8px;
  }
  .filter-section {
    background-color: #ffffff;
    padding-bottom: 50px;
  }
  .filter-container .row > div {
    border-bottom: 2px solid #f9f9f9;
  }
  .filter-container .search-btn {
    margin-top: 40px;
    height: auto;
  }
  .gallery-section {
    background-image: none !important;
    background-color: #fff;
    padding-top: 0;
  }
  .gallery-section > img {
    display: none;
  }
  .gallery-section-content {
    position: static;
  }
  .gallery-content-box {
    box-shadow: none;
  }
  .after-filter-tags ul li {
    margin: 0 !important;
    display: block;
    text-align: center;
    float: left;
    width: 100%;
  }
  .after-filter-tags ul li:after {
    display: none;
  }
  .rating-section {
    display: none;
  }
  footer {
    padding: 0 15px;
  }
  .mobile-show-only.active {
    display: block;
  }
  .location-dropdown-container.active:not(.mobile-show-only),
  .guests-dropdown-container.active:not(.mobile-show-only) {
    display: none;
  }
  .items-counter {
    width: 90%;
    margin: 0 auto;
  }
  .item-box .def-number-input button {
    padding: 0 5px;
  }
  .gallery-section-content .container {
    padding: 0;
  }

  .mobile-hotels-gallery {
    display: block;
  }
  .section-heading h2 {
    font-size: 1.786rem;
  }
  /*.blog-section .container {
    padding: 0;
  }*/
  .blog-heading-area {
    text-align: center;
    padding: 30px 0;
  }
  .blog-heading-area div {
    width: 100%;
  }
  .blog-heading-area a {
    display: none;
  }
  .blog-box {
    padding: 0 40px;
    margin-bottom: 0px;
  }
  .desktop-footer {
    display: none;
  }
  .mobile-social-nav ul li {
    margin: 0 10px;
  }
  .mobile-social-nav ul li a {
    color: #a9acb5;
    font-size: 1.571rem;
  }
  .mobile-social-nav ul {
    margin-bottom: 20px !important;
  }
  .mobile-social-nav ul + a {
    margin-bottom: 20px;
    display: inline-block;
  }
  .mobile-social-nav p {
    margin: 0;
    color: #2c3f58;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
  }
  .signup-sidebar-img-container {
    display: none;
  }
  .form-container > button {
    top: 20px;
    position: absolute;
    right: 30px;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .mobile-nav {
    display: block;
  }
  .navigation-section {
    display: none;
  }
  .menu-icon {
    text-align: center;
    padding-top: 12px;
  }
  .mobile-nav .col-md-10 {
    text-align: right !important;
    padding-right: 55px;
  }
  .destination-dropdown {
    top: 126px;
  }
  .country_list {
    height: 100%;
  }
  .country_list li a {
    font-size: 1rem;
  }
  .cities_list li a {
    padding: 8px 15px;
  }
  .town_list li a {
    padding: 8px 15px;
  }
  .view_all {
    padding-left: 15px !important;
    padding-top: 0 !important;
  }
  .location_triger {
    padding-left: 20px !important;
  }
  .location_triger .location_icon {
    left: 0;
  }
  .date_picker span i {
    margin-right: 0;
  }
  .date_picker span {
    margin: 0;
  }
  .filter-container button {
    font-size: 1rem;
  }
  .mobile-show-only {
    display: none !important;
  }
  .after-filter-tags ul li {
    margin: 0 30px;
  }
  .after-filter-tags ul li:after {
    right: -20px;
  }
  .rating-section {
    padding: 50px;
  }
  .footer-right-content {
    padding: 40px 0 0 40px;
  }
  .footer-widget ul li a {
    font-size: 0.857rem;
  }
  .footer-widget ul li {
    margin-bottom: 10px;
  }
  .footer-right-content h3 {
    font-size: 1.357rem;
  }
  .blog-box {
    padding: 0 30px;
  }
}
@media (min-width: 1024px) and (max-width: 1200px) {
  .carousel-dropdown {
    top: 0;
    left: 0;
    left: 55.5%;
    top: 18%;
    margin-top: 4%;
  }
}
