@import "./variables";

/* ********************************* */
/* Generic */
/* ********************************* */
html,
body {
  font-family: $base-font;
  font-size: 14px; // 16px original
  margin: 0;
  padding: 0;
  color: $text-primary-color;
  font-weight: normal;
}
.font_open-sans{
  font-family: $base-font;
}
a{
  color: inherit;
}
.font_nunito_sans {
  font-family: $nunito_sans-font;
}
.font_lato {
  font-family: $lato-font;
}
.font_helvetica {
  font-family: $helvetica-font;
}
.font_montserrat{
  font-family: $montserrat;
}
.font_corben {
  font-family: $corben;
}
body {
  display: block;
  width: 100%;
  position: relative;
}
.__normal-btn{
  outline: none !important;
  border: none;
  background: transparent;
  padding: 0;
}
section,
footer {
  display: block;
  float: left;
  width: 100%;
  position: relative;
}

/* ********************************* */
/* Scrollbar */
/* ********************************* */
::-webkit-scrollbar {
  width: 8px;
  height: auto;
}
::-webkit-scrollbar-track {
  -webkit-border-radius: 0;
  border-radius: 0;
  background: #f5f5f5;
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0;
  border-radius: 0;
  background: #000;
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #000;
}
.hide {
  display: none;
}
.show {
  display: block;
}
.scrollmagic-pin-spacer {
  width: 100%;
}
.full-width {
  width: 100% !important;
}
.full-height {
  height: 100%;
}
.space-evenly {
  justify-content: space-evenly;
}
.social-bar {
  font-family: $montserrat;
  margin: 0;
  a {
    padding: 10px;
    height: 37px;
    width: 37px;
    background-color: #e9e9e9;
    margin: 7px;
    position: relative;
    i.fab {
      color: #5c5655;
      position: absolute;
      top: 26%;
    }
  }
  .fb {
    background-color: #3b5998;
    color: white;
    width: 200px;
    font-weight: 500;
    span {
      position: absolute;
      top: 23%;
      left: 18%;
      font-size: 0.857em;
    }
    i.fab {
      position: relative;
      color: white;
      top: -3px;
      left: 2%;
    }
  }
}
.no-style-btn {
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  background-color: transparent;
  &:active,
  &:hover,
  &:focus {
    border: none;
    outline: none;
  }
}
.justify-content-evenly{
  justify-content: space-evenly;
}
.counter-box {
  display: flex;
  justify-content: space-between;
  p {
    margin: 0;
    padding: 0;
    font-family: $montserrat;
    font-size: 0.929rem; // check if it looks good on 1rem
    color: #000;
    font-weight: 600;
  }
  & button {
    margin: 0;
    padding: 0;
    font-size: 0.643rem;
    background-color: #fff;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    border: none;
    box-shadow: 0px 1px 3px #0000004f;
    color: #3a81b8;
    position: relative;
    & i {
      position: absolute;
      top: 0.9em;
      left: 0.9em;
    }
  }
  & button:hover,
  & button:active,
  & button:focus {
    outline: none;
    border: none;
  }
  & .def-number-input {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    & input {
      border: none;
      width: 25px;
      text-align: center;
      font-family: $lato-font;
      font-size: 0.929rem;
      color: #2c3f58;
      font-weight: 500;
    }
    & input:hover,
    & input:active,
    & input:focus {
      outline: none;
    }
    & input::-webkit-outer-spin-button,
    & input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
}
.right-aligned-on-desktop {
  text-align: right;
}

/* Google Maps Styles */
.gm-style {
  .gm-style-iw-c {
    border-radius: 0 !important;
    box-shadow: unset;
  }
}
.gm-style img {
  max-width: 100% !important;
}
.maps-custom-info-window {
  max-width: 350px;
  height: 100%;
  background-color: white;
  .slick-slider {
    width: 100%;
    background-color: #ddd9;
  }
  .hotel-meta-inner span {
    font-size: 0.786rem;
  }
  .hotel-meta-inner h3 {
    font-size: 1.143rem;
  }
  .hotel-meta-inner {
    padding: 0 20px;
  }
  .hotel-meta-price {
    font-family: $montserrat;
    padding: 5px;
    p {
      font-size: 1rem; //check if it can be removed
    }
  }
}
.font-weight-semi-bold{
  font-weight: 600;
}
.font-weight-mediam-bold{
  font-weight: 500
}
.font-weight-400 {
  font-weight: 400
}
.__my-container{
  width: 78%;
}
.__primary-text-color{
  color: #3A81B8;
}
.f1{
  flex: 1;
}
.__my-container-v2, .__my-container{
  margin: auto;
}
.__my-container-v2{
  width: 70%;
}
.container{
  max-width: 100%;
  padding: 0 12%;
}
@media (max-width: 1200px) {
  .__my-container-v2{
    max-width: 78%;
  }
  .container{
    max-width: 90%;
    padding: 0 3%;
  }
}
@media (max-width: 900px){
  .__my-container, .__my-container-v2{
    max-width: 90%;
  }
}
@import "./layout";

.__orange-text{
  color: #FD9C52;
}
.blue-text {
  color: $text-secondary-color
}
.__no-highlight{
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -moz-user-select: -moz-none;   
  -o-user-select: none;
}
.__btn{
  border: none;
  outline: none !important;
  cursor: pointer;
  background: transparent;
  color: #1B2124;
  border-radius: 2px;
  font-weight: 700;
  font-family: $montserrat;
  font-size: 0.857rem;
  padding: 0.6em 1.366em; // 9.6 - 21.76
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
  &::after{
    content: '';
    width: 100%;
    padding-top: 100%;
    border-radius: 50%;
    background: rgba($color: grey, $alpha: 0.2);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: scale(0) translate(-50%, -50%);
  }
  &:active::after{
    animation: forwards RippleEffect 0.3s;
  }
  &.__primary{
    background: #1B2124;
    color: #fff;
  }
}
.fix-height-result{
  // width: 719px; // it breaks the picture
  object-fit: contain;
  display: block;
}
@keyframes RippleEffect{
  0%{
    transform: scale(0) translate(-50%, -50%);
  }
  100%{
    transform: scale(1) translate(-50%, -50%);
  }
}
.__grey-text{
  color: #cccccc;
}
.parentDisable{
  position: fixed;
  top: 0;
  left: 0;
  background: transparent;
  opacity: 0.8;
  z-index: 2000;
  height: 100%;
  width: 100%;
}

.overlay-box {
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  color: white;
  opacity: .8;
  z-index: 1000;
}

.p-50 {
  padding: 50px
}

.loading {
  z-index: 2000;
}

.flex {
  display: flex;
}

.font-lato {
  font-family: $lato-font;
}

.modal-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

.pre-wrap {
  white-space: pre-wrap;
}

.pointer {
  cursor: pointer;
}

.display-block {
  display: block;
}

.text-align-center {
  text-align: center;
}

body.modal-open #root{
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
}

.gray-label {
  color: $gray-label
}

.form-control {
  height: calc(2rem + .75rem + 2px);
}

.color-white{
  color: white;
}

.btn-primary {
  border-color: unset !important;
  &:hover {
    border-color: unset !important;
  }
}

.capitalize {
  text-transform: capitalize;
}

.corben {
  font-family: $corben;
}

.pagination-container {
  padding: 20px 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  .pagination {
    width: 100%;
    align-items: center;

    .page-item .page-link {
      font-weight: 500;
      font-size: 16px;
      font-style: normal;
      line-height: 16px;
      color: $text-primary-color;
      border: unset;
      background: unset;
    }
    .page-item.active .page-link {
      color: $text-secondary-color;
      background: unset;
      box-shadow: unset;
    }

    li:first-child {
      margin-right: auto;
      border: none;
      box-shadow: 0px 2px 2px rgba(44, 63, 88, 0.04), 0px 4px 4px rgba(44, 63, 88, 0.03), 0px 8px 8px rgba(44, 63, 88, 0.03),
      0px 16px 16px rgba(44, 63, 88, 0.03), 0px 0px 32px rgba(44, 63, 88, 0.05), 0px 0px 64px rgba(44, 63, 88, 0.05);
      border-radius: 50%;
      width: 48px;
      height: 48px;
      span span:not(.sr-only) {
        font-size: 30px;
        position: relative;
        top: 5px;
        left: 7px;
      }
      a span:not(.sr-only) {
        color: $text-secondary-color;
        font-size: 30px;
        position: relative;
        top: 5px;
        left: 7px;
      }
    }
    li:last-child {
      margin-left: auto;
      border: none;
      box-shadow: 0px 2px 2px rgba(44, 63, 88, 0.04), 0px 4px 4px rgba(44, 63, 88, 0.03), 0px 8px 8px rgba(44, 63, 88, 0.03),
      0px 16px 16px rgba(44, 63, 88, 0.03), 0px 0px 32px rgba(44, 63, 88, 0.05), 0px 0px 64px rgba(44, 63, 88, 0.05);
      border-radius: 50%;
      width: 48px;
      height: 48px;
      span span:not(.sr-only) {
        font-size: 30px;
        position: relative;
        top: 5px;
        left: 10px;
      }
      a span:not(.sr-only) {
        color: $text-secondary-color;
        font-size: 30px;
        position: relative;
        top: 5px;
        left: 10px;
      }
    }
  }
}
@media (max-width: 576px) {
  .pagination-container {
    padding: 20px 0;
  }
}

@media (min-width: 577px) and (max-width: 999px) {
  .pagination-container {
    padding: 20px 10%;
  }
}