/* ********************************* */
         /* Rating Section */
/* ********************************* */
.rating-section{
  padding: 50px 100px 0;
  background-color: #f7f7f7;
}
.rating-heading{
  border-bottom: 1px solid #9797978c;
  padding-bottom: 50px;
}
.rating-heading ul{
  list-style: none;
  margin: 0;
  padding: 0;
}
.rating-heading ul li{
  display: inline-block;
  margin-right: 5px;
}
.rating-heading ul li img {
  height: 25px;
  margin-top: -10px;
  margin-left: 15px;
}
.rating-heading ul li h3 {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.429rem;
  color: #646161;
  font-weight: 400;
}
.rating-heading ul li i{
  color: #ffd100;
}
.rating-heading ul li strong{
  font-weight: 800;
  color: #000;
  font-family: 'Lato', sans-serif;
  font-size: 1.286rem;
  padding-right: 7px;
}
.rating-heading ul li:last-child {
  border-left: 3px solid #cccccc1f;
}
.rating-heading p {
  color: #646161;
  font-size: 1rem;
  margin-bottom: 0;
}
.review-box{
  padding: 50px 0 50px 0;
}
.review-box-rating{
  margin-bottom: 10px;
}
.review-box-rating i{
  color: #ffd100;
}
.review-box h3 {
  color: #000;
  font-family: 'Lato', sans-serif;
  font-size: 1.286rem;
  font-weight: 700;
}
.review-box p {
  color: #686666;
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 5px;
}
.review-box span {
  color: #adaaaa;
  font-family: 'Lato', sans-serif;
  font-size: 0.857rem;
  font-weight: 500;
  letter-spacing: 1px;
}
.review-slider .owl-nav .owl-prev{
  left: -55px;
}
.review-slider .owl-nav .owl-next{
  right: -55px;;
}
.review-slider .owl-nav button{
  position: absolute;
  top: 100px;
  background-color: transparent !important;
  outline: none !important;
  border: none !important;
}
.review-slider .owl-nav button:hover,
.review-slider .owl-nav button:focus,
.review-slider .owl-nav button:active{
  background-color: transparent !important;
  outline: none !important;
  border: none !important;
}
.review-slider .owl-nav button img {
  height: 30px;
}

// .feefowidget-standard {
//   width: 100% !important;
//   text-align: center;
// }